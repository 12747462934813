import { PropsWithChildren } from 'react'
import CmsIcon from '@components/Cms/CmsComponents/CmsIcon/CmsIcon'
import Countdown from '@components/common/UI/Countdown'
import CmsCta from '@components/Cms/CmsComponents/CmsCta'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import {
  StyledLandscapeBannerTextContainer,
  StyledLandscapeBannerTitle,
  StyledLandscapeBannerWrapper,
  StyledLandscapeBannerCTAContainer,
} from './LandscapeBannerContent.style'
import useReplaceLocaleInCMSUrl from '@hooks/useReplaceLocaleInCMSUrl'

type LandscapeBannerContentProps = {
  placement?: IPlacement | ICMCollection
  teaser?: ILXTeaser
  teaserIndex?: number
  showCtas?: boolean
}

export const LandscapeBannerContent: React.FC<PropsWithChildren<LandscapeBannerContentProps>> = ({
  placement,
  teaser,
  showCtas = true,
  children,
}) => {
  const replaceLocaleInCmsUrls = useReplaceLocaleInCMSUrl()
  const item = placement
    ? 'items' in placement
      ? placement?.items?.find(isLXTeaser)
      : placement?.teasableItems?.find(isLXTeaser)
    : teaser

  const {
    teaserPreTitle,
    teaserLXCallToActionSettings,
    teaserIcon,
    teaserHideExpiredCountdown,
    teaserCountdownStart,
    teaserCountdownUntil,
    teaserText1,
    teaserTitle1,
  } = item || {}

  return (
    <StyledLandscapeBannerWrapper>
      <div>
        {teaserIcon && <CmsIcon teaserIcon={teaserIcon} />}
        {teaserCountdownUntil && (
          <Countdown
            startDateInSeconds={teaserCountdownStart}
            endDateInSeconds={teaserCountdownUntil}
            hideOnExpire={teaserHideExpiredCountdown}
          />
        )}
        {teaserPreTitle && <div>{teaserPreTitle}</div>}
        {teaserTitle1 && (
          <StyledLandscapeBannerTitle variant="h1" component="div">
            {teaserTitle1}
          </StyledLandscapeBannerTitle>
        )}
        {teaserText1 && (
          <StyledLandscapeBannerTextContainer
            dangerouslySetInnerHTML={{ __html: replaceLocaleInCmsUrls(teaserText1) }}
          />
        )}
        {children}
      </div>
      {showCtas && Array.isArray(teaserLXCallToActionSettings) && teaserLXCallToActionSettings.length > 0 && (
        <StyledLandscapeBannerCTAContainer>
          {teaserLXCallToActionSettings.map((target, i) => {
            if (i > 1) return null
            return (
              <CmsCta
                key={target.callToActionText}
                actionSettings={target}
                data-element-id={`X_X_${i}Placement_LandscapeBanner_CTA`}
              />
            )
          })}
        </StyledLandscapeBannerCTAContainer>
      )}
    </StyledLandscapeBannerWrapper>
  )
}

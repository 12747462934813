import CmsBroadContentWrapper, {
  CmsBroadContentWrapperProps,
} from '@components/Cms/CmsComponents/CmsBroadContentWrapper/CmsBroadContentWrapper'
import CmsTeaserDetailText from '@components/Cms/CmsComponents/CmsTeaserDetailText'
import CmsTeaserTitle from '@components/Cms/CmsComponents/CmsTeaserTitle'
import { styled } from '@mui/material/styles'

export const StyledLandscapeBannerWrapper = styled(CmsBroadContentWrapper, {
  name: 'LandscapeBannerWrapper',
})<CmsBroadContentWrapperProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'absolute',
  bottom: theme.spacing(18),
  height: 'auto',
  top: 'unset',
  width: '100%',
  padding: theme.spacing(0, 8),
  gap: theme.spacing(12),
  alignItems: 'center',
  textAlign: 'center',

  [theme.breakpoints.up('sm')]: {
    bottom: 'unset',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 'unset',
    alignItems: 'flex-start',
    textAlign: 'left',
  },

  [theme.breakpoints.up('md')]: {
    height: 'auto',
    padding: theme.spacing(0, 16),
    gap: theme.spacing(8),
    right: 0,
    margin: '0 auto',
    maxWidth: 1440,
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 32),
  },
}))

export const StyledLandscapeBannerTitle = styled(CmsTeaserTitle, {
  name: 'LandscapeBanner',
  slot: 'Title',
})(() => ({
  textTransform: 'uppercase',
}))

export const StyledLandscapeBannerTextContainer = styled(CmsTeaserDetailText, {
  name: 'LandscapeBanner',
  slot: 'TextContainer',
})(({ theme }) => ({
  width: '100%',

  // text from cms wysiwyg
  '& p': {
    ...theme.typography.subtitle1,
  },
}))

export const StyledLandscapeBannerCTAContainer = styled('div', {
  name: 'LandscapeBanner',
  slot: 'CTAContainer',
})(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column wrap',
  gap: theme.spacing(4),
  //ctas are centered on mobile
  alignItems: 'center',

  '& > a': {
    height: 'auto',
    maxWidth: 285,
    [theme.breakpoints.up('md')]: {
      maxWidth: 343,
    },
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    '& > a': {
      width: 'auto',
    },
  },
}))
